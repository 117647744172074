import { Controller } from "stimulus"

export default class extends Controller {

  dragstart(event) {
      event.dataTransfer.setData("application/drag-key", event.target.getAttribute("data-link-id"))
      event.dataTransfer.effectAllowed = "move"
  }

  dragover(event) {
      event.preventDefault()
      return true
  }

  dragenter(event) {
      event.preventDefault()
      return false
  }

  dragleave(event) {
    event.preventDefault()
    return false
  }

  drop(event) {
      var data = event.dataTransfer.getData("application/drag-key")
      const dropTarget = event.target
      console.log("DROPTARGET")
      console.log(dropTarget)
      const draggedItem = this.element.querySelector(`[data-link-id='${data}']`);
      const positionComparison = dropTarget.compareDocumentPosition(draggedItem)
      console.log("BETTER DROPTARGET")
      console.log(dropTarget.closest("div ul"))
      console.log(dropTarget.closest("div ul").parentNode)
      const dropTargetParent = dropTarget.closest("div ul").parentNode
      if ( positionComparison & Node.DOCUMENT_POSITION_FOLLOWING) {
          console.log ("AVANT")
          dropTargetParent.insertAdjacentElement('beforebegin', draggedItem);
      } else if ( positionComparison & Node.DOCUMENT_POSITION_PRECEDING) {
          dropTargetParent.insertAdjacentElement('afterend', draggedItem);
          console.log ("APRES")
      }
      event.preventDefault()
  }


  dragend(event) {
    console.log("dragend")
    var matches = document.querySelectorAll("div[data-link-id]")
    var i = 1;
    matches.forEach(function(linkItem) {
      var linkId = linkItem.getAttribute("data-link-id")
      console.log(linkId)
      var position = i
      console.log(position)
      i++
      fetch(`/change/${linkId}/${position}`)
    });
  }
}
