import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["color", "coloroutput"]

  connect() {
    console.log('hello color')
    console.log(this.colorTarget.selectedOptions)
    console.log(this.colorTarget.selectedOptions.length)
    if(this.colorTarget.selectedOptions.length > 0)
    {
      console.log("ah oui salut")
      this.coloroutputTarget.innerHTML = `<div class="bg-${this.colorTarget.selectedOptions[0].value}-100 w-10 h-10 inline-block"></div><div class="bg-${this.colorTarget.selectedOptions[0].value}-500 w-10 h-10 inline-block"></div><div class="bg-${this.colorTarget.selectedOptions[0].value}-800 w-10 h-10 inline-block"></div>`
    }
  }

  colordisplay() {
    this.coloroutputTarget.textContent = ''
    console.log('COLOR IS CHANGED')
    console.log(this)
    console.log(this.colorTarget.selectedOptions)
    console.log(this.colorTarget.selectedOptions[0])
    console.log(this.colorTarget.selectedOptions[0].value)
    this.coloroutputTarget.innerHTML = `<div class="bg-${this.colorTarget.selectedOptions[0].value}-100 w-10 h-10 inline-block"></div><div class="bg-${this.colorTarget.selectedOptions[0].value}-500 w-10 h-10 inline-block"></div><div class="bg-${this.colorTarget.selectedOptions[0].value}-800 w-10 h-10 inline-block"></div>`
  }
}
