import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output", "slug", "oavailable", "ravailable", "savailable" ]

  connect() {
    this.outputTarget.textContent = ''
    console.log('hello live')
  }

  display() {
    if (this.slugTarget.value == "") {
      this.outputTarget.textContent = ''
      this.outputTarget.textContent = 'Please select a name for your link!'
      console.log('LINK IS CHANGED')
    }
    else {
      this.outputTarget.textContent = ''
      this.outputTarget.textContent = `${this.slug}`
      console.log('LINK IS CHANGED')
    }
  }

  available(event) {

    console.log(this.slugTarget.value)
    this.savailableTarget.innerHTML = ''
    this.oavailableTarget.innerHTML = ''
    this.ravailableTarget.innerHTML = ''

    if (this.slugTarget.value != "") {
      console.log('availablechecking')
      this.oavailableTarget.innerHTML = ''
      this.ravailableTarget.innerHTML = ''
      // $(this.oavailableTarget).innerHTML = xhr.response
      // this.element.children[0].setAttribute("data-content", xhr.response);
      // this.oavailableTarget.innerHTML = xhr.response

      // fetch(this.data.get('checkunique/'+this.domain+'/'+this.slugTarget.value))
      //   .then(response => response.text())
      //   .then(html => {
      //     this.oavailableTarget.innerHTML = html
      //   })

      var valid = /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(this.slugTarget.value);
      console.log(this.slugTarget.value);
      if (valid) {
        fetch(this.urlForLink)
          .then(response => response.text())
          .then(html => this.oavailableTarget.innerHTML = html)
      }
      else {
        this.ravailableTarget.innerHTML = '<div class="text-center py-4 lg:px-4"><div class="p-2 bg-red-200 items-center text-red-700 leading-none lg:rounded-full flex lg:inline-flex" role="alert"><svg class="h-6 w-6 text-red-600 inline-block mr-4" stroke="currentColor" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/></svg><span class="font-semibold mr-2 text-left flex-auto">Only accepts alphanumeric characters and dashes.</span><svg class="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/></svg></div></div>'
        this.oavailableTarget.innerHTML = ''
      }
    }
    else {
      console.log("FIELDS NOT COMPLETE SORRY")
    }
  }

  get urlForLink() {
    // return `${this.data.get("url")}/${this.pickerTarget.value}`
    return `/checkunique/${this.slugTarget.value}`;
  }

  getavailable() {
    console.log('getavailable');

  }

  get slug() {
    console.log('GET SLUG')
    return this.slugTarget.value
  }
}
