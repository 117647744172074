import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["integration", "instruction"]

  connect() {
    console.log('hello instructions')
    console.log(this.integrationTarget.selectedOptions)
    console.log(this.integrationTarget.selectedOptions.length)
    if(this.integrationTarget.selectedOptions.length > 0)
    {
      console.log("ah oui salut")
    }
  }

  display() {
    this.instructionTarget.textContent = ''
    console.log('COLOR IS CHANGED')
    console.log(this)
    console.log(this.integrationTarget.selectedOptions)
    console.log(this.integrationTarget.selectedOptions[0])
    console.log(this.integrationTarget.selectedOptions[0].value)

    if(this.integrationTarget.selectedOptions[0].value == "typeform")
    {
      this.instructionTarget.innerHTML = `<div class="rounded-md bg-blue-50 p-4"><div class="flex"><div class="flex-shrink-0"><svg class="h-5 w-5 text-blue-400" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"/></svg></div><div class="ml-3 flex-1 md:flex md:justify-between"><p class="text-sm leading-5 text-blue-700">Paste here the url of your Typeform - <em>for instance, it could look like "https://xxxxxxx.typeform.com/xoeh8m"</em></p></div></div></div>`
    }
    if(this.integrationTarget.selectedOptions[0].value == "youtube")
    {
      this.instructionTarget.innerHTML = `<div class="rounded-md bg-blue-50 p-4"><div class="flex"><div class="flex-shrink-0"><svg class="h-5 w-5 text-blue-400" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"/></svg></div><div class="ml-3 flex-1 md:flex md:justify-between"><p class="text-sm leading-5 text-blue-700">Paste here the ID of your YouTube video - <em>for instance, it could look like "bigB30ufXYI"</em>, it's usually just after "watch?v=" in the URL.</p></div></div></div>`
    }
    if(this.integrationTarget.selectedOptions[0].value == "mailchimp")
    {
      this.instructionTarget.innerHTML = `<div class="rounded-md bg-blue-50 p-4"><div class="flex"><div class="flex-shrink-0"><svg class="h-5 w-5 text-blue-400" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"/></svg></div><div class="ml-3 flex-1 md:flex md:justify-between"><p class="text-sm leading-5 text-blue-700">On the Signup Forms menu of your audience, select "Embedded Forms" and look, inside the form tag, the url that contains <em>"us8.list-manage.com/subscribe"</em>. After <em>"action="</em>, paste here all the url between quotes. <br /><em>Example: https://muuusi.us8.list-manage.com/subscribe/post?u=171b9e139cb0afe758ee1b7cb&amp;id=e5a0b908f4.</em></p></div></div></div>`
    }
    if(this.integrationTarget.selectedOptions[0].value == "shopify")
    {
      this.instructionTarget.innerHTML = `<div class="rounded-md bg-blue-50 p-4"><div class="flex"><div class="flex-shrink-0"><svg class="h-5 w-5 text-blue-400" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"/></svg></div><div class="ml-3 flex-1 md:flex md:justify-between"><p class="text-sm leading-5 text-blue-700">It's not working now, but soon! Shoot me an email at mehdi@lesensdelahutte.com to be notified when it's live.</p></div></div></div>`
    }
  }
}
